$theme-primary:    rgb(69, 69, 69);
$theme-secondary:  rgb(238, 238, 238);
$theme-red: #b00;

$grey-base:        #000;
$grey-darker:      lighten($grey-base, 13.5%); // #222
$grey-dark:        lighten($grey-base, 20%);   // #333
$grey:             lighten($grey-base, 33.5%); // #555
$grey-light:       lighten($grey-base, 46.7%); // #777
$grey-lighter:     lighten($grey-base, 93.5%); // #eee
$grey-lightest:    lighten($grey-base, 100%); // #fff

$dark-grey-overlay: rgba($theme-primary, .6);

$enable-rounded: false;
$primary: $theme-primary;
$mobile-breakpoint: 768px;
