@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/ekko-lightbox/ekko-lightbox.less';
@import 'mixins';

@mixin transition-all() {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  @include transition-all();

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle & {
    color: $color;
    background-color: darken($background, 5%);
    border-color: darken($border, 7%);
  }

  &:active,
  &.active,
  .open > .dropdown-toggle & {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

@mixin sans-serif-font() {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

@mixin serif-font() {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

$theme-primary:    rgb(69, 69, 69);
$theme-secondary:  rgb(238, 238, 238);
$theme-red: #b00;

$grey-base:        #000;
$grey-darker:      lighten($grey-base, 13.5%); // #222
$grey-dark:        lighten($grey-base, 20%);   // #333
$grey:             lighten($grey-base, 33.5%); // #555
$grey-light:       lighten($grey-base, 46.7%); // #777
$grey-lighter:     lighten($grey-base, 93.5%); // #eee
$grey-lightest:    lighten($grey-base, 100%); // #fff

$dark-grey-overlay: rgba($theme-primary, .6);

$enable-rounded: false;
$primary: $theme-primary;
$mobile-breakpoint: 768px;

.photo-slider {
  border: 6px solid $theme-primary;
}

.carousel-control-prev,
.carousel-control-next {
  @media(max-width: 567px) {
    display: none;
  }
}

// .carousel-control-prev {
//   left: -120px;
// }
//
// .carousel-control-next {
//   right: -120px;
// }


.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 32px;
  height: 32px;
}

.carousel-control-next-icon {
  background-image: url('/images/arrow-right-solid.svg');
}

.carousel-control-prev-icon {
  background-image: url('/images/arrow-left-solid.svg');
}

.dark-grey {
  color: $theme-primary;
}

.light-grey {
  color: $theme-secondary;
}

.bg-dark-grey {
  background-color: $theme-primary;
  color: $grey-lightest;

  .btn {
    color: $grey-lightest;
  }

  .btn-outline-primary {
    border-color: $grey-lightest;

    &:hover {
      background-color: $grey-lightest;
      color: $theme-primary;
    }
  }

  a {
    color: $grey-lightest;

    &:hover {
      color: $theme-secondary;
    }
  }
}

.bg-light-grey {
  background-color: $theme-secondary;
  color: $theme-primary;
}

.bg-white {
  background-color: $grey-lightest;
  color: $theme-primary;
}

.gallery-item {
  img {
    transition: transform .1s ease-in-out, border-color .1s ease-in-out;
    border: 7px solid $theme-primary;
  }

  &:hover {
    img {
      transform: scale(1.03);
      border-color: $grey-dark;
    }
  }
}

.hero-wrapper {
  margin-top: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0 0;
  min-height: calc(100vh - 100px);

  .hero-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: calc(100vh - 200px);
  }

  .hero-logo {
    max-width: 575px;
  }
}


.navbar {
  box-shadow: 0 3px 5px rgba($theme-primary, .3);

  @media(max-width: $mobile-breakpoint) {
    padding-bottom: 1em;

    > .container {
      justify-content: center;

      .navbar-brand {
        margin: 0;
      }
    }
  }

  .logo {
    margin-bottom: 16px;
    width: 250px;

    @media(min-width: $mobile-breakpoint) {
      margin-bottom: 0;
      padding: 8px;
      width: 400px;
    }
  }
}

section {
  position: relative;
  border-bottom: 7px solid $theme-primary;

  &::after {
    position: absolute;
    bottom: 0;
    z-index: 1;
    box-shadow: 0 6px 4px 4px $theme-primary;
    width: 100%;
    content: '';
  }
}

.map-wrapper {
  min-height: 500px;
}

.columns-3 {
  columns: 3 200px;
  column-gap: 2em;
}

.btn {
  padding-right: 32px;
  padding-left: 32px;
  font-size: 26px;
}

.form-control:focus,
.btn:focus,
.btn.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.register-panel {
  h2 {
    background-color: $theme-primary;
    padding: 16px;
    color: $grey-lightest;
    font-size: 48px;
  }

  form {
    padding: 16px;
  }

  .register-result {
    color: $theme-red;
    font-size: 14px;

    &.success {
      background-color: #0c3;
      padding: 12px 16px;
      color: white !important;
    }
  }

  .announcement {
    background-color: $theme-red;
    text-align: center;
    color: white;

    h2 {
      margin-bottom: 0;
      background-color: $theme-red;
      padding-bottom: 0;
    }

    p {
      margin-bottom: 0;
      padding: 0 16px 16px;
    }
  }
}

.footer-notes {
  padding: 8px 0;
  font-size: 12px;
}

body {
  font-family: 'Helvetica', sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.btn {
  font-family: 'Baskerville', Serif;
}

h1,
.h1 {
  font-size: 45px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

.btn {
  font-size: 22px;

  @media(min-width: $mobile-breakpoint) {
    font-size: 26px;
  }
}


.intro-heading {
  font-size: 24px;
}

.smaller {
  font-size: .75em;
}
